<template>
	<div>
		<quill-editor ref="myTextEditor" v-model="content" :options="editorOption" style="min-height:400px;"></quill-editor>
		
		
		
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import {
		quillEditor
	} from 'vue-quill-editor';
	import quillConfig from './com/qedit_config.js'
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				content: "",
				editorOption: quillConfig,
				ImageList: [],
				msgcontent: "",
				taped: false,
				zjid: 0,
				tcid: 0,
				orderid:0,
				tcinfo: null,
				zjinfo: null,
				lastDom: null,
				process:0
			}
		},
		mounted() {

		},
		methods: {
			onEditorChange: (editor,html,text)=>{
				console.log(html)
				console.log(text)
			}

		}

	}
</script>

<style>
	.ql-toolbar {
		border: 0;
		display: none;
	}

	.ql-container.ql-snow {
		border: 0px solid #ccc;
		background-color: #FFFFFF;
		color: #000000;
	}
</style>
