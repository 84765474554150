

// toolbar工具栏的工具选项（默认展示全部）
const toolOptions = [
  
];
export default {
    placeholder: '请输入您要咨询的内容',
    theme: 'snow',  // 主题
    modules: {
        toolbar: {
            container: toolOptions
           
        }
    }
};